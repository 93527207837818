<template>
    <div class="loginView">
        <a href="/" class="smallLogo"></a>
        <div class="loginViewInner">
            <div style="flex-grow: 1;" class="align-self-center">
            </div>    
            <div class="align-self-center">
                <b-form @submit="resetPassword">
                    <b-form-group>
                        <div class="logoContainer">
                            <img src="../assets/img/logo_poolinq_white.svg">
                            <div class="spacer xs"></div>
                            <p>{{ $t('login.passwordRecoveryHeadline') }}</p>
                        </div>
                        <div class="spacer xl"></div>
                        <div class="">
                            <p>{{ $t('login.newPassword') }}</p>
                            <div class="spacer xs"></div>
                            <input type="password" v-model="password" class="form-control">
                        </div>
                        <div class="spacer m"></div>
                        <div class="">
                            <p>{{ $t('login.repeatPassword') }}</p>
                            <div class="spacer xs"></div>
                            <input type="password" v-model="confirmPassword" class="form-control">
                        </div>
                        <div class="spacer m"></div>
                        <b-button :disabled="password.length < 3 || password !== confirmPassword" class="loginButton" type="submit" variant="primary">{{ $t('login.submitPasswordForgot') }}</b-button>
                        <div class="spacer s"></div>
                        <router-link class="forgotPassword" to="/login">
                            <span>{{ $t('login.backToLogin') }}</span>
                        </router-link> 
                    </b-form-group>
                </b-form>
            </div>
        </div>    
    </div>
</template>
<script>
    import DataService from '@/services/StrapiService'

    export default {
        name: 'ResetPassword',
        data() {
            return {
                password: '',
                confirmPassword: '',
                done: false,
                error: false,
                strapiUrl: DataService.getServerUrl()
            }
        },
        methods: {
            async resetPassword(e) {
                e.preventDefault()
                this.axios.post(this.strapiUrl+`/auth/reset-password`, {
                    code: this.$route.query.code,
                    password: this.password,
                    passwordConfirmation: this.confirmPassword
                })
                .then(() => {
                    this.done = true
                    this.$router.push("login")
                })
                .catch(e => {
                    e;
                    this.error = true
                })
            }
        },
    }
</script>
<style scoped>
</style>